import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "components/front/Layout";
import AdminLayout from "components/admin/AdminLayout";
import { Route } from "react-router-dom";
import { publicRoutes } from "./FrontRoutes";
import { adminRoutes } from "./AdminRoutes";
import Login from "components/admin/user/Login";
import ForgotPassword from "components/admin/user/ForgotPassword";
import Resetpassword from "components/admin/user/ResetPassword";
import ResetPass from "components/front/Login/SignUp/ResetPass/ResetPass";

const childFrontRoutes = [];
const childAdminRoutes = [];

publicRoutes.forEach((item) => {
  let r = {
    path: item.path,
    element: item.component,
  };

  childFrontRoutes.push(r);
});

adminRoutes.forEach((item) => {
  let r = {
    path: item.path,
    element: item.component,
  };

  childAdminRoutes.push(r);
});

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />}>
        {childFrontRoutes.map((item, index) => (
          <Route key={index} path={item.path} element={item.element} />
        ))}
      </Route>

      <Route path="/admin/login" element={<Login />} />
      <Route path="/admin/forgot" element={<ForgotPassword />} />
      <Route path="/admin/reset" element={<Resetpassword />} />
      <Route path="/resetPass" element={<ResetPass/>}/>

      <Route path="/admin" element={<AdminLayout />}>
        {childAdminRoutes.map((item, index) => (
          <Route key={index} path={item.path} element={item.element} />
        ))}
      </Route>
    </>
  )
);
