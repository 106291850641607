import React from "react";
import AdminNavbar from "../Navbars/AdminNavbar";

export default function HeaderStats() {
  return (
    <>
      {/* Header */}
      <div className="flex items-center relative bg-blue-950 text-white min-h-[60px]">
        <div className="px-4 md:px-10 mx-auto w-full my-4">
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-5">
              <h3 className="text-[32px]">Edupreneur Village Control Panel</h3>
            </div>
            <div className="justify-end">
              <AdminNavbar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
