// import React from "react";
import React, { useEffect, useState } from "react";
import { getActiveTestimonialList } from "services/testimonials.service";
import { Col, Row } from "react-bootstrap";
import "./Home.css";

import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { NavLink, Link } from "react-router-dom";
import { subscribeNewsletter } from "services/newsletter.service";
import { blogDataByTitle } from "services/blog.service";
import { IMAGEURL } from "global";
import { dynamicTraction } from "services/configuration.service";

function Home() {
  const [users, setUsers] = useState([]);
  const [newsEventsData, setNewsEventData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorClass, setErrorClass] = useState("");
  const [dynamicTractions, setDynamicTractions] = useState([]);
  var obj = {
    title1: "News",
    title2: "Events",
  };
  useEffect(() => {
    getActiveTestimonialList()
      .then((response) => response.json())
      .then((data) => {
        setUsers(data.Result);
      });
    blogDataByTitle(obj).then((response) => {
      console.log(response);
      setNewsEventData(response.data.Result);
    });
    dynamicTraction().then((response) => {
      console.log(response.data);
      setDynamicTractions(response.data.Result[0]);
    });
  }, []);

  // console.log(state);
  let setting = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let setting2 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const navigate = useNavigate();

  const subscribe = (e) => {
    e.preventDefault();
    subscribeNewsletter(e.target.email.value).then((response) => {
      if (response.data.Result && response.data.Result.length > 0)
        setErrorClass("text-red-500");
      else setErrorClass("text-lime-500");
      setErrorMsg(response.data.Message);

      setTimeout(() => {
        setErrorMsg("");
        e.target.email.value = "";
      }, 2000);
    });
    console.log(e.target.email.value);
  };

  const valueDigit = (value) => {
    console.log(value);
    switch (value) {
      case value > 999 && value <= 99999:
        return `Math.floor(${value}/1000)K`;
      case value > 99999 && value <= 999999:
        return `Math.floor(${value}/100000)L`;
      case value > 999999 && value <= 9999999:
        return `Math.floor(${value}/1000000)Cr.`;
      default:
        return `${value}`;
    }
  };
  return (
    <>
      <section className="pg-home">
        {/* <Header /> */}
        {/* Banner Section */}
        <section className="ev-Banner_sec">
          <div className="d-flex flex-column justify-content-center">
            <Row>
              <Col lg={7}>
                <div className="coverup">
                  <h1>
                    Supporting education entrepreneurs with turnkey-support
                    ecosystem
                  </h1>
                  <p>
                    Looking for investment to build your Edtech startup?
                    <br />
                    Now accepting business plans
                  </p>
                  <HashLink to="marathon" smooth>
                    <button className="submit-btn">Submit</button>
                  </HashLink>
                </div>
              </Col>
            </Row>
          </div>
          <div className="ocean">
            <svg
              className="waves"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shape-rendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                />
              </defs>
              <g className="parallax">
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="0"
                  fill="rgba(255,255,255,1"
                />
                {/* <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                    <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                    <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                    <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />  */}
              </g>
            </svg>
          </div>
        </section>
        {/* About Section */}
        <section className="ev-about-sec">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col lg={6}>
                  <div className="grid-50 about-left_sec">
                    <div className="inner_wrapper">
                      <p>
                        Edupreneur Village is the largest
                        <span> EdTech focused ecosystem</span> in the country,
                        providing equity funding investment, acceleration and
                        market access opportunities to leading edupreneurs.
                        Along with funding for startups, we help them and their
                        teams scale and grow effectively into scalable business.
                      </p>
                      <p>
                        We invest early, at the Seed or Pre series A stage
                        startups in India, and in firms that are primarily
                        targeting the Indian market, and have a global ambition.
                      </p>
                      <Link to="about">
                        <button className="main-cto-second">
                          About Us
                          <img src="images/Home/white_arrow.svg" alt="imgNotFound"/>
                        </button>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="about-right_sec">
                    <div className="about_img">
                      <iframe
                        src="https://www.youtube.com/embed/dQx2TvhFwMc"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="flexi">
                <div className="grid-100 stats_sec">
                  <ul>
                    {Object.keys(dynamicTractions).map((key, index) => {
                      const value = Object.values(dynamicTractions)[index];
                      console.log(valueDigit(value));
                      return (
                        <li>
                          <div className="counter-box">
                            <a>
                              <span className="count">{valueDigit(value)}</span>
                              {key !== "Edupreneurs" &&
                              key !== "Application" ? (
                                <span style={{ color: "red" }}>+</span>
                              ) : (
                                ""
                              )}
                            </a>
                            <p>{key}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Offering Section */}
        <section className="ev-offering_sec">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col>
                  <div className="">
                    <h2 className="main_head">Our Offerings</h2>
                    <p className="para">
                      We support education entrepreneurs with an active
                      experience in the EdTech and education industry, backed
                      with sector focused, EdTech investors.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <div className="first">
                    <div className="offering_box">
                      <a href="">
                        <span>
                          <img src="images/Home/icons_Capital.svg" alt="imgNotFound" />
                        </span>
                        <h3>Access to Equity Funding</h3>
                        <p className="para" style={{ display: "none" }}>
                          Equity investment in early-stage EdTech startups at
                          Seed/ Pre-Series A stage
                        </p>
                      </a>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="grid-25 second">
                    <div className="offering_box">
                      <a href="">
                        <span>
                          <img src="images/Home/icons_customers.svg" alt="imgNotFound" />
                        </span>
                        <h3>Access To Customers</h3>
                        <p className="para" style={{ display: "none" }}>
                          Partnership with Auro Scholar, a micro-scholarship
                          programme, provides market access
                        </p>
                      </a>
                    </div>
                  </div>
                </Col>

                <Col lg={3}>
                  <div className="grid-25 third">
                    <div className="offering_box">
                      <a href="">
                        <span>
                          <img src="images/Home/icons_mentor.svg" alt="imgNotFound" />
                        </span>
                        <h3>Access to Mentorship</h3>
                        <p className="para" style={{ display: "none" }}>
                          Mentoring startups to create a robust Business Plan
                          and become investment ready
                        </p>
                      </a>
                    </div>
                  </div>
                </Col>

                <Col lg={3}>
                  <div className="grid-25 fourth">
                    <div className="offering_box">
                      <a href="">
                        <span>
                          <img src="images/Home/icons_ecosystem.svg" alt="imgNotFound" />
                        </span>
                        <h3>Access To Ecosystem</h3>
                        <p className="para" style={{ display: "none" }}>
                          An unparalleled platform for EdTech entrepreneurs with
                          robust industry networks, linkages, and relationships
                          with ecosystem players.
                        </p>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* News and Events */}
        <section className="ev-news_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h2 className="main-head">News & Events</h2>
                <div className="inner_wrapper">
                  {/* News Slider */}
                  <Slider {...setting}>
                    {newsEventsData?.length > 0
                      ? newsEventsData?.map((item, index) => (
                          <div className="item" key={index}>
                            <img
                              src={`${IMAGEURL}${item.Image}`}
                              alt="No Image Found"
                              className="new-img"
                            />
                            <a className="news_head">
                              <span className="date">
                                {item.UploadDate.slice(4, 10)}
                              </span>
                              {item.Title}
                            </a>
                            <p>{item.ShortDescription}</p>
                          </div>
                        ))
                      : ""}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Testimonials */}
        <>
          <section className="ev-testimonials_sec">
            <div className="flexi">
              <div className="container">
                <div className="flexi">
                  <h2 className="main_head">What People Say About Us?</h2>

                  <div className="inner_wrapper">
                    {/* Swiper carousel */}

                    <Slider {...setting2}>
                      {users && users.length > 0
                        ? users.map((user, index) => (
                            <div key={index} className="item2">
                              <img
                                src={`${IMAGEURL}${user.ProfilePic}`}
                                className="testi-img"
                              />
                              <h5>
                                {user.Name}
                                <span>{user.Position}</span>
                              </h5>
                              <p>{user.Content}</p>
                            </div>
                          ))
                        : ""}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
        ;{/* Subscribe */}
        <section className="ev-subscribe_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h2 className="main_head">Subscribe to our Newsletter</h2>
                <p>To get the latest updates</p>
                <form className="subscribe" onSubmit={subscribe}>
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="Enter Your Email Here..."
                  />
                  <button className="green_btn">
                    Subscribe <img src="images/Home/white_arrow.svg" />
                  </button>
                </form>
                <div className={`text-center ${errorClass}`}>{errorMsg}</div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default Home;
