import React from "react";
import { NavLink } from "react-router-dom";

export default function FooterSmall(props) {
  return (
    <>
      <footer className="relative w-full bottom-0 bg-blueGray-800 ">
        <div className="container  px-4">
          <hr className="mb-1 border-b-1 border-blueGray-600" />
          <div className="grid grid-cols-6 gap-4 ">
            <div></div>
            <div className="col-span-5 ">
              <div className="flex flex-wrap items-center md:justify-between justify-center">
                <div className="w-full md:w-4/12 px-4">
                  <div className="text-sm text-blueGray-500 font-semibold py-1 text-left md:text-left">
                    Copyright © {new Date().getFullYear()}{" "}
                    <NavLink
                      href="/"
                      className="text-[#000] hover:text-blueGray-300 text-sm font-semibold py-1"
                    >
                      || Edupreneur Village
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
