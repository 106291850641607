import React, { useEffect, useState } from "react";
import { getcareerList } from "services/career.service";
import { Col, Row } from "react-bootstrap";
import "./Career.css";

function Career() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    console.log("Hiiiii");
    getcareerList()
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUsers(data.Result);
      });
  }, []);
  return (
    <>
      <section className="pg-career">
        {/* Banner */}
        <section className="ev-Banner_sec">
          <Row>
            <Col lg={6} className="d-flex justify-content-center flex-column">
              <div className="">
                <h1>Join Us at Edupreneur Village</h1>
                <p>Work at the most dynamic workplace</p>
                <button className="main-cto-first">Submit</button>
              </div>
            </Col>
            <Col lg={6}>
              <div className="grid-50 banner_image">
                <img src="images/Career/banner.png" />
              </div>
            </Col>
          </Row>
        </section>
        {/* Career Info */}
        <section className="carrer_sec">
          <div className="flexi">
            <div className="container">
              <Row className="card_sec">
                <Col lg={6}>
                  <Row>
                    <Col lg={6}>
                      <div className="card">
                        <img src="images/Career/building.png" />
                        <h4>Beautiful Infrastructure</h4>
                        <p>
                          Enjoy a comfortable office surrounded with modern
                          infra with natural essence
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="card">
                        <img src="images/Career/location.png" />
                        <h4>Easy Location</h4>
                        <p>
                          Enjoy a comfortable office surrounded with modern
                          infra with natural essence
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="card">
                        <img src="images/Career/group.png" />
                        <h4>Great Co-workers</h4>
                        <p>
                          Enjoy a comfortable office surrounded with modern
                          infra with natural essence
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <div className="career_info">
                    <h2>Career</h2>
                    <p>
                      Edupreneur Village is an EdTech focused ecosystem, an
                      initiative by Sri Aurobindo Society and located in New
                      Delhi, India. Not only do we invest in EdTechstartups, we
                      provide them all the support it takes to reach the Series
                      A stage. This includes mentoring startups and working with
                      the startup founders to create an investable business
                      plan, pitch decks and in-depth Information Books to make
                      them investment ready and help make presence through the
                      massive education ecosystem we have.
                    </p>
                    <button className="main-cto-first">Learn More</button>
                  </div>
                </Col>
              </Row>
              <div className="flexi">
                <div className="grid-50"></div>
              </div>
            </div>
          </div>
        </section>
        {/* Opening Job */}
        <section className="open_job-sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <div className="job_view">
                  <h3>
                    Job Openings
                    <span className="available">
                      <e>3</e> Jobs Available
                    </span>
                  </h3>
                  <form>
                    <ul>
                      <div className="search_box">
                        <input type="text" name="" placeholder="Search.." />
                      </div>
                      <li>
                        <select>
                          <option>Choose State</option>
                          <option>State 01</option>
                          <option>State 02</option>
                          <option>State 03</option>
                        </select>
                      </li>
                      <li>
                        <select>
                          <option>Choose Department</option>
                          <option>Department 01</option>
                          <option>Department 02</option>
                          <option>Department 03</option>
                        </select>
                      </li>
                      <li>
                        <select>
                          <option>Choose Work Type</option>
                          <option>Work Type 01</option>
                          <option>Work Type 02</option>
                          <option>Work Type 03</option>
                        </select>
                      </li>
                    </ul>
                  </form>
                </div>

                <div className="job_post-sec">
                  <ul>
                    {users.length > 0
                      ? users.map((user, index) => (
                          <>
                            <li>
                              <div className="job_card">
                                <h4>{user.Title}</h4>
                                <div className="job_detail">
                                  <span className="for">{user.Position}</span> |{" "}
                                  <span className="Department">
                                    {user.Department}
                                  </span>
                                </div>
                                <button className="apply">Apply Now</button>
                              </div>
                            </li>
                            {/* <li>
                              <div className="job_card">
                                <h4>Search</h4>
                                <div className="job_detail">
                                  <span className="for">Intern</span> |{" "}
                                  <span className="Department">
                                    Business Analyst
                                  </span>
                                </div>
                                <button className="apply">Apply Now</button>
                              </div>
                            </li>
                            <li>
                              <div className="job_card">
                                <h4>Search</h4>
                                <div className="job_detail">
                                  <span className="for">Intern</span> |{" "}
                                  <span className="Department">
                                    Business Analyst
                                  </span>
                                </div>
                                <button className="apply">Apply Now</button>
                              </div>
                            </li> */}
                          </>
                        ))
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Real Impact */}
        <section className="highlighhts">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <div className="highlight_title">
                  <h2>
                    Make a real impact in ed-tech industry with Edupreneur
                    Village
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Recruitment Process */}
        <section className="requirtment_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <div className="recruit">
                  <h2>Learn Our Recruitment Process</h2>
                  <ul>
                    <li>
                      <div className="card">
                        <img src="images/Career/agreement.png" />
                        <h4>CV Submission</h4>
                        <p>
                          Submit your CV or resume through our online portal if
                          you meet our requirements
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="card">
                        <img src="images/Career/phone.png" />
                        <h4>Phone Screening</h4>
                        <p>
                          After looking at your CV you will be invited for a
                          telephone interview at a time of your choosing
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="card">
                        <img src="images/Career/search_doc.png" />
                        <h4>Skill Assessment</h4>
                        <p>
                          You will be invited at our head office to take a
                          skills and knowledge assessment
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="card">
                        <img src="images/Career/members.png" />
                        <h4>Final Interview</h4>
                        <p>
                          If you can pass all stages we invite you for a final
                          interview with our CEO and HR executive
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default Career;
