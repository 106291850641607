import React, { useEffect, useState } from "react";
import "./RelatedPost.css";
import { recentPost } from "services/blog.service";
import { IMAGEURL } from "global";
import { NavLink } from "react-router-dom";

function RelatedPost() {
  const [relatedPostContent,setRelatedPostContent] = useState();
  const [BlogID,setBlogID]=useState();
  
  // let relatedPostContent = [
  //   {
  //     id: "1",
  //     subText: "Subtext",
  //     title: "Blog Title",
  //   },
  //   {
  //     id: "2",
  //     subText: "Subtext",
  //     title: "Blog Title",
  //   },
  //   {
  //     id: "3",
  //     subText: "Subtext",
  //     title: "Blog Title",
  //   },
  //   {
  //     id: "4",
  //     subText: "Subtext",
  //     title: "Blog Title",
  //   },
  // ];
  // let imageSrc = "../images/Home/banner.svg";
  useEffect(()=>{
    recentPost().then((response)=>{
      setRelatedPostContent(response.data.Result);
      console.log(response);
    })
  }, [])
  return (
    <>
      <section className="relatedPost">
        <h4>Recent Posts</h4>
        {console.log(relatedPostContent)}
        {relatedPostContent?.length ? relatedPostContent?.map((item,index) => (
          
          <div className="related-block" key={index}>
            <div className="related-item">
              <div className="related-img">
              
                <img src={`${IMAGEURL}${item.Image}`} />
              </div>
              <div className="related-content" >
                <NavLink to={{ pathname:`/BlogContent/${item.BlogID}`, 
                }}>
                  <span >
                <p className="sub-text" onClick={()=>console.log(BlogID)}>{item.ShortDescription}</p>
                <p className="blog-head">{item.Title}</p>
                  </span>
                </NavLink>
             
              </div>
            </div>
          </div>
        )):""}
        {/* <div className="related-block">
          <div className="related-item">
            <div className="related-img">
              <img src="images/Home/banner.svg" />
            </div>
            <div className="related-content">
              <p className="sub-text">Subtext</p>
              <p className="blog-head">Blog Title</p>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
}

export default RelatedPost;
