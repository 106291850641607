import axios from "axios";
const { APIURL } = require("global");

const sendMailForContactUs = async (data) => {
  try {
    const URL = APIURL + "users/contactUs";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

export {
    sendMailForContactUs,
}