import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { ActiveTimeline } from "services/timeline.service";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";

import "./Timeline.css";

// import required modules
import { EffectFade, EffectFlip, Navigation, Pagination } from "swiper";
import { IMAGEURL } from "global";

export default function Timeline() {
  // const slidesText = ["slide 1", "slide 2", "slide 3"];
  const slidesText = [];
  const [timelineData, setTimelineData] = useState([]);

  const dateData = (value) => {
    switch (value) {
      case value === 1:
        return "st";
      case value === 2:
        return "nd";
      case value === 3:
        return "rd";
      default:
        return "th";
    }
  };

  useEffect(()=>{
    ActiveTimeline().then((response) => {
      setTimelineData(response.data.Result);
    });
  },[])

  useEffect(() => {
    // Update the pagination text after the Swiper component is rendered
    timelineData.map((item, index)=>{
      var num = index + 1;
      slidesText[index] = "slide " + num;
    });
    const paginationElements = document.querySelectorAll(
      ".swiper-pagination-bullet"
    );

    var count = 0;
    paginationElements.forEach((el, index) => {
      el.textContent = slidesText[count];
      count += 1;
      // console.log(el);
      return;
    });

    
  }, [timelineData]);

  return (
    <>
      <Swiper
        modules={[Pagination, EffectFlip]}
        effect="flip"
        className="mySwiper"
        direction="vertical"
        spaceBetween={20}
        type="custom"
        pagination={{ clickable: true }}
        navigation={{ clickable: true }}
        style={{ height: "80vh" }} // Set the desired height
      >
        {timelineData.length > 0
          ? timelineData.map((item, index) => (
                <SwiperSlide
                  style={{
                    backgroundImage: `url(${IMAGEURL}${item.Image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  className="slide-main"
                  key={index}
                >
                  <div className="swiper-slide-content">
                    <ul className="timeline-year">
                      <li>
                        <h4 className="stroke-text">
                          {item.Date.slice(8, 10)}
                          <sup style={{ fontSize: "45px", lineHeight: "45px" }}>
                            {dateData(item.Date.slice(7, 10))}
                          </sup>
                        </h4>
                      </li>
                      <li>
                        <span className="year">
                          {item.Date.slice(4, 8)} {item.Date.slice(11, 15)}
                        </span>
                      </li>
                    </ul>
                    <h5 className="timeline-title">{item.Title}</h5>
                    <hr />
                    <p className="timeline-text">{item.Description}</p>
                  </div>
                </SwiperSlide>
            ))
          : "No Data"}
      </Swiper>
    </>
  );
}
