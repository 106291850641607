import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import { NavLink } from "react-router-dom";
import { CommonService } from "services/common.service";
import { getActiveBlogCategory } from "services/blog.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faBlog,
  faBriefcase,
  faCaretDown,
  faCircleInfo,
  faCompactDisc,
  faGift,
  faHouse,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [showDrop, SetshowDrop] = useState(false);
  const [mdrop, setmdrop] = useState(false);
  // const [menuOpen, setMenuOpen] = useState(false);
  // const toggleMenu = () => {
  //   setMenuOpen(!menuOpen);
  // };
  const navigate = useNavigate();

  const commonService = new CommonService();

  const [show, setShow] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    localStorage.getItem("isUserLoggedIn")
  );
  const [mediaData, setMediaData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNavClose = (url) => {
    navigate(url);
    handleClose();
  };

  useEffect(() => {
    commonService.Authenticated.subscribe((res) => {
      // setIsUserLoggedIn(true)
      console.log('im here')
    });
    getActiveBlogCategory().then((resp) => {
      setMediaData(resp.data.Result);
    });
  }, []);

  const logout = (e) => {
    e.preventDefault();
    console.log("logout");
    localStorage.removeItem("loggedUser");
    localStorage.removeItem("isUserLoggedIn");

    navigate("/");
  };

  const dropDown = () => {
    SetshowDrop(!showDrop);
  };
  const mdropDown = () => {
    setmdrop(!mdrop);
  };

  return (
    <>
      <header className="header">
        {["lg"].map((expand) => (
          <Navbar key={expand} bg="" expand={expand} className="w-100">
            <div className="inner_wrapper">
              <Link to="" className="logo">
                <img src="../images/Logo.svg" />
              </Link>
              <Navbar.Toggle
                // aria-controls={`offcanvasNavbar-expand-${expand}`}
                onClick={handleShow}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                show={show}
                onHide={handleClose}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Edupreneur Village
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="justify-content-end">
                  <ul className="menu">
                    <li
                      className="pe-auto"
                      state={{ id: 1 }}
                      onClick={() => handleNavClose("/")}
                    >
                      <FontAwesomeIcon
                        icon={faHouse}
                        className="mobile-menu-icon"
                      />
                      <NavLink>Home</NavLink>
                    </li>
                    <li
                      className="pe-auto"
                      state={{ id: 1 }}
                      onClick={() => handleNavClose("about")}
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="mobile-menu-icon"
                      />
                      <NavLink>About</NavLink>
                    </li>

                    <li onClick={dropDown}>
                      <label className="toggle">Our Offerings</label>
                      <FontAwesomeIcon
                        icon={faGift}
                        className="mobile-menu-icon"
                      />
                      <NavLink
                        className="pe-auto"
                        state={{ id: 1 }}
                        // onClick={() => handleNavClose("#")}
                      >
                        Our Offerings
                      </NavLink>
                      <input type="checkbox" id="drop-1" />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        rotation={`${showDrop ? "180" : "0"}`}
                        className="mobile-drop"
                      />

                      <ul
                        className={`${
                          showDrop ? "dropdown-content" : "nodrop"
                        }`}
                      >
                        <li>
                          <NavLink
                            to={"/marathon"}
                            className="pe-auto"
                            state={{ id: 1 }}
                            onClick={() => handleNavClose("marathon")}
                          >
                            Investment Marathon
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={"/acceleration"}
                            className="pe-auto"
                            state={{ id: 1 }}
                            onClick={() => handleNavClose("acceleration")}
                          >
                            Acceleration
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={"/partnership"}
                            className="pe-auto"
                            state={{ id: 1 }}
                            onClick={() => handleNavClose("Partnership")}
                          >
                            Partnership
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to={"/portfolios"}
                        className="pe-auto"
                        state={{ id: 1 }}
                        onClick={() => handleNavClose("portfolios")}
                      >
                        <FontAwesomeIcon
                          icon={faBriefcase}
                          className="mobile-menu-icon"
                        />
                        Portfolios
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        to={"/career"}
                        className="pe-auto"
                        state={{ id: 1 }}
                        onClick={() => handleNavClose("career")}
                      >
                        Career
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink
                        to={"/funding"}
                        className="pe-auto"
                        state={{ id: 1 }}
                        onClick={() => handleNavClose("funding")}
                      >
                        <FontAwesomeIcon
                          icon={faBlog}
                          className="mobile-menu-icon"
                        />
                        Blogs
                      </NavLink>
                    </li>
                    <li onClick={mdropDown}>
                      <FontAwesomeIcon
                        icon={faCompactDisc}
                        className="mobile-menu-icon"
                      />
                      <label className="toggle">Media</label>
                      <NavLink
                        // to={"/media"}
                        className="pe-auto"
                        state={{ key: "pr" }}
                        // onClick={() => handleNavClose("media")}
                      >
                        Media
                      </NavLink>
                      <input type="checkbox" id="drop-1" />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        rotation={`${mdrop ? "180" : "0"}`}
                        className="mobile-drop"
                      />
                      <ul
                        className={`${mdrop ? "dropdown-content" : "nodrop"}`}
                      >
                        {mediaData.length > 0
                          ? mediaData.map((item, index) => (
                              <li key={index}>
                                <NavLink
                                  to={"/media"}
                                  className="pe-auto"
                                  state={{ key: item.BlogCategoryID }}
                                  onClick={() => handleNavClose("media")}
                                >
                                  {item.Title}
                                </NavLink>
                              </li>
                            ))
                          : ""}
                        {/* <li>
                          <NavLink
                            to={"/media"}
                            className="pe-auto"
                            state={{ key: "pr" }}
                            onClick={() => handleNavClose("media")}
                          >
                            PR
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={"/media"}
                            className="pe-auto"
                            state={{ key: "news" }}
                            onClick={() => handleNavClose("media")}
                          >
                            News
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={"/media"}
                            className="pe-auto"
                            state={{ key: "events" }}
                            onClick={() => handleNavClose("media")}
                          >
                            Event
                          </NavLink>
                        </li> */}
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to={"/contactUs"}
                        className="pe-auto"
                        state={{ id: 1 }}
                        onClick={() => handleNavClose("contactUs")}
                      >
                        <FontAwesomeIcon
                          icon={faAddressBook}
                          className="mobile-menu-icon"
                        />
                        Contact us
                      </NavLink>
                    </li>
                    {isUserLoggedIn !== "true" && (
                      <li>
                        <div
                          to={"/login"}
                          className="pe-auto"
                          state={{ id: 1 }}
                          onClick={() => handleNavClose("login")}
                        >
                          <button className="head-cto">
                            <FontAwesomeIcon
                              icon={faUser}
                              className="mobile-menu-icon"
                            />
                            Login/Signup
                            <img
                              src="../images/Home/white_arrow.svg"
                              className="login-arrow"
                            />
                          </button>
                        </div>
                      </li>
                    )}
                    {isUserLoggedIn === "true" && (
                      <li className="header-account">
                        <label className="toggle">My Account</label>
                        <NavLink
                          className="pe-auto head-cto"
                          onClick={() => handleNavClose("#")}
                        >
                          My Account
                        </NavLink>
                        <input type="checkbox" id="drop-1" />
                        <ul>
                          <li>
                            <NavLink
                              className="pe-auto"
                              to={"/dashboard"}
                              onClick={() => handleNavClose("")}
                            >
                              My Application
                            </NavLink>
                          </li>
                          <li>
                            <Link className="pe-auto" onClick={logout}>
                              Logout
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </div>
          </Navbar>
        ))}
      </header>
    </>
  );
};

export default Header;
