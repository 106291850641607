import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./Marathon.css";
import { Link } from "react-router-dom";
import { dynamicInvestmentMarathon } from "services/configuration.service";
import Timeline from "../Timeline/Timeline";
import Timeline2 from "../Timeline/Timeline2";

function Marathon() {
  const [investmentMarathonData, setInvestmentMarathonData] = useState([]);
  useEffect(() => {
    dynamicInvestmentMarathon().then((response) => {
      setInvestmentMarathonData(response.data.Result[0]);
    });
  }, []);
  return (
    <>
      <section className="pg-marathon">
        {/* Marathon Banner */}
        <section className="ev-Banner_sec">
          <div className="d-flex justify-content-center flex-column">
            <div className="grid-100 coverup">
              <h1 className="first_up">
                Investment Marathon
                <span className="count" style={{ color: "#F28C2E" }}>{" "}
                  {investmentMarathonData.InvestmentMarathonVersion?.slice(0,1)}
                </span>
              </h1>
              <p>
                {" "}
                Inviting Investment Applications For 20
                {investmentMarathonData.InvestmentMarathonYear}
              </p>
              <Link to="/login">
                <button className="main-cto-first">Register Now</button>
              </Link>
            </div>
          </div>
        </section>
        {/* Marathon Info */}
        <section className="ev-about-sec">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col lg={6} className="d-flex">
                  <div className="about-left_sec">
                    <div className="inner_wrapper">
                      <h2 className="main_head">About Investment Marathon</h2>
                      <p>
                        <span>Edupreneur Village Investment Marathon</span> is
                        focused on Seed & Pre-Series A investment for early
                        stage EdTech Entrepreneurs. It aims at identifying and
                        supporting innovative solutions that can significantly
                        lift the education system or address pressing problems
                        in the overall learning ecosystem of India. Not only do
                        we invest, but also provide the support required to
                        reach Series A stage funding, which includes access to
                        the market through various initiatives of Sri Aurobindo
                        Society.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="grid-50 about-right_sec">
                    <div className="about_img">
                      <iframe
                        src="https://www.youtube.com/embed/dQx2TvhFwMc"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="flexi"></div>
            </div>
          </div>
        </section>
        {/* Marathon Features */}
        <section className="ev-features">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col lg={6}>
                  <div className="grid-50">
                    <div className="left_sec">
                      <h2>When to reach out</h2>
                      <ul>
                        <li>Seed & Pre-Series A Stage investment</li>
                        <li>
                          Pre-revenue or revenue stage with proven proof of
                          concept
                        </li>
                        <li>
                          Ready to grow through access to millions of customers
                          through partnerships offered by Edupreneur Village
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="grid-50">
                    <div className="right_sec">
                      <h2 className="main_head">What we look for</h2>
                      <ul>
                        <li>
                          A well balanced team of entrepreneurs with passion to
                          disrupt the EdTech industry
                        </li>
                        <li>
                          Leveraging technology to solve real life education
                          problem
                        </li>
                        <li>
                          Strong Competitive Advantage in order to stand out
                          from competitors
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        <section className="marathon-timeline">
          <Timeline />
        </section>
        {/* Marathon Invest 
      <section className="ev-invest">
        <div className="flexi">
          <div className="timeline">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div
                  className="swiper-slide"
                  style={{
                    backgroundImage:
                      "url(https://unsplash.it/1920/500?image=11",
                  }}
                  data-year="2011"
                >
                  <div className="swiper-slide-content">
                    <ul className="timeline-year">
                      <li>
                        <h4 className="stroke-text">
                          31
                          <sup style={{ fontSize: "45px", lineHeight: "45px" }}>
                            th
                          </sup>
                        </h4>
                      </li>
                      <li>
                        <span className="year">Jan 2022</span>
                      </li>
                    </ul>
                    <h5 className="timeline-title">Application Screening</h5>
                    <hr />
                    <p className="timeline-text">
                      The applications submitted by edupreneur will be evaluated
                      by our dedicated investment team. Screening process will
                      be conducted from 15th November 2021 to 15th January 2022.
                    </p>
                  </div>
                </div>
                <div
                  className="swiper-slide"
                  style={{
                    backgroundImage:
                      "url(https://unsplash.it/1920/500?image=12",
                  }}
                  data-year="2012"
                >
                  <div className="swiper-slide-content">
                    <span className="timeline-year">2012</span>
                    <h4 className="timeline-title">Our nice super title</h4>
                    <p className="timeline-text">
                      Lorem ipsum dolor site amet, consectetur adipscing elit,
                      sed do eisumod tempor incididut ut labore et dolore magna
                      aliqua. Ut enim ad mimim venjam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </section>*/}
        {/* Why Apply */}
        <section className="ev-apply">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h2 className="main_head">Why Apply</h2>
                <Row>
                  <Col lg={6}>
                    <div className="left_sec">
                      <h3>Investment</h3>
                      <ul>
                        <li>India's only EdTech focused VC fund</li>
                        <li>Get funded in the range of $200K - $500K</li>
                        <li>Annual cycle investment window for limited time</li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="left_sec">
                      <h3>Partnership</h3>
                      <ul>
                        <li>
                          Get direct access to the students who participate in
                          Auro Scholar, the monthly scholarship program based on
                          student's performance
                        </li>
                        <li>
                          Monthly scholarships provided to students can be used
                          to buy EdTech partner's products
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="left_sec">
                      <h3>Teacher Network</h3>
                      <ul>
                        <li>
                          Teachers are biggest influencers for purchase
                          decisions of students
                        </li>
                        <li>
                          Tap the teacher network of 20+ lakh teachers & 4 lakh
                          schools covered under of Sri Aurobindo Society’s
                          teacher innovative program - ZIIEI
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="left_sec">
                      <h3>Collaboration with Governments</h3>
                      <ul>
                        <li>
                          Signed MoU with 31 State Govt. & UTs for National
                          Scale Education Transformation Initiative
                        </li>
                        <li>
                          Signed MoU with CBSE for providing programmes of Sri
                          Aurobindo Society and our partners to 80+ lakh
                          students in CBSE schools
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Link to="/login">
                  <button className="green_btn">
                    SUBMIT INVESTMENT APPLICATION
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default Marathon;
