import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { spaceRegex } from "components/RegexValidation";
// import { nameRegex } from "components/RegexValidation";
import {
  CreatePartner,
  UpdatePartner,
  getparnerList,
  GetPartnerlListByID,
} from "services/partner.service";
import { handleKeyPressforSpecialCharacters } from "components/RegexValidation";
import { handleKeyPressForNumbers } from "components/RegexValidation";

import { useRef } from "react";
import { IMAGEURL } from "global";

export const PartnerAdd = () => {
  const [error, setError] = useState(false);
  const param = useParams();

  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [partners, setPartners] = useState("");
  const [size, setSize] = useState("");
  const [profilePicPreview, setProfilePicPreview] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [emailID, setEmailID] = useState("");
  const [website, setWebsite] = useState("");
  const [PartnerID, setPartnerID] = useState("");
  const [createBy, setCreateBy] = useState(user.AdminID);
  const [disable, setDisable] = useState(false);

 

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text/plain");
    const nameRegex = /^[A-Za-z]+$/;

    if (!nameRegex.test(pastedText)) {
      event.preventDefault();
      // Display an error message or handle it accordingly
    }
  };

  const uploadProfilePic = (e) => {
    let file = e.target.files[0];
    let ext = file !== undefined ? file.name?.split(".")[1] : "";

    if (
      ext.toLowerCase() === "jpeg" ||
      ext.toLowerCase() === "jpg" ||
      ext.toLowerCase() === "png"
    ) {
      if (file) {
        const reader = new FileReader();
        setSize(file.size);
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      }
    } else if (ext === "") {
      return false;
    } else {
      e.preventDefault();
      setProfilePic("");
      toast.error("Image must be jpg, jpeg and png extension");
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setProfilePic(btoa(binaryString));
    setProfilePicPreview("data:image/png;base64," + btoa(binaryString));
  };

  useEffect(() => {
    if (typeof param.id !== "undefined") {
      GetPartnerlListByID(param.id).then((response) => {
        let PartnerDetail = response.data.Result[0];
        setPartnerID(PartnerDetail.PartnerID);
        setName(PartnerDetail.Name);
        setContact(PartnerDetail.Contact);
        setEmailID(PartnerDetail.EmailID);
        setWebsite(PartnerDetail.Website);
        setProfilePicPreview(PartnerDetail.ProfilePic);
      });
    }
  }, []);

  const addPartner = async (e) => {
    e.preventDefault();
    if (name.trim() === '') {
      // Handle the case when the input field is blank
      toast.warn("Input field cannot be blank");
      return;
    }

    const partner = {
      partnerID: e.target.id.value,
      name: name,
      contact: contact,
      emailID: emailID,
      website: website,
      createBy: JSON.parse(localStorage.getItem("user")).AdminID,
      profilePic: param.id ? (profilePic !== "" ? profilePic : "") : profilePic,
    };

    if (e.target.id.value === "" || e.target.id.value === "undefined") {
      CreatePartner(partner).then((data) => {
        if (data.data.Status === "Success" && !data.data.Error) {
          toast.success(data.data.Message);
          setDisable(true)
          setTimeout(() => {
            navigate("/admin/partnerlist");
          }, 2000);
        } else {
          toast.error(data.data.message);
        }
      });
    } else {
      UpdatePartner(partner).then((data) => {
        if (data.data.Status === "Success" && !data.data.Error) {
          toast.success(data.data.Message);
          setDisable(true)
          setTimeout(() => {
            navigate("/admin/partnerlist");
          }, 2000);
        } else {
          toast.error(data.data.message);
        }
      });
    }
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                {param.id ? "Edit" : "Add"} Partners
              </h3>
            </div>

            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={addPartner}>
                    <input type="hidden" name="createby" value={createBy} />
                    <input type="hidden" name="id" value={PartnerID} />

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        autoFocus
                        value={name}
                        maxLength={20}
                        required
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Name"
                        onKeyPress={handleKeyPressforSpecialCharacters}
                        onPaste={handlePaste}
                        // onChange={(e) => setName(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setName(e.target.value);
                          }
                        }}

                        // onChange={(e) => {
                        //   if (e.target.value === " ") {
                        //     // setUserNameError(false);
                        //     e.preventDefault();
                        //   } else if (
                        //     !(e.target.value === "") &&
                        //     !nameRegex.test(e.target.value)
                        //   ) {
                        //     // setUserNameError(true);

                        //     setName(e.target.value);
                        //   } else {
                        //     // setUserNameError(false);
                        //     setName(e.target.value);
                        //   }
                        // }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Contact <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="contact"
                        value={contact}
                        maxLength={10}
                        minLength={10}
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}
                        placeholder="Contact"
                        onKeyPress={handleKeyPressForNumbers}
                        onChange={(e) => setContact(e.target.value)}
                      />

                      <label
                        className="block uppercase my-2 text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        E-mail <span className="text-red-500">*</span>
                      </label>

                      <input
                        type="email"
                        name="email"
                        value={emailID}
                        // required
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}
                        placeholder="E-mail"
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        // onChange={(e) => setEmailID(e.target.value)}
                        onChange={(e) => {
                          setEmailID(e.target.value);
                        }}
                      />
                      <label
                        className="block uppercase my-2 text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Website <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        // required
                        name="website"
                        value={website}
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}
                        placeholder="Website"
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        // onChange={(e) => setWebsite(e.target.value)}
                        onChange={(e) => {
                          setWebsite(e.target.value);
                        }}
                      />

                      <label
                        className="block uppercase my-2  text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Profile Pic <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="file"
                        // required
                        name="ProfilePic"
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}
                        accept=".jpeg,.jpg,.png"
                        onChange={uploadProfilePic}
                      />
                      {profilePic === "" ? (
                        <img
                          src={`${IMAGEURL}${profilePicPreview}`}
                          width="100"
                          alt=""
                        />
                      ) : (
                        <img src={profilePicPreview} width="100" alt="" />
                      )}

                      <div className="text-left mt-6">
                        <button
                          className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit" disabled={disable}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default PartnerAdd;
