import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RelatedPost from "../RelatedPost/RelatedPost";
import "./BlogContent.css";
import { getBlogContentByID } from "services/blog.service";
import { useParams } from "react-router";
import Image_not_available from "assets/img/Image_not_available.png";
import * as DOMPurify from "dompurify";

function BlogContent(props) {
  const param = useParams();
  const [blogdata, setblogdata] = useState([]);
  const blogcontent = async () => {
    let response = await getBlogContentByID(param.id);
    console.log("response", response);
    setblogdata(response.data.Result);
  };
  console.log("blogdata", blogdata);
  useEffect(() => {
    blogcontent();
  }, []);
  return (
    <>
    {console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb")}
      {blogdata?.map((item) => (
        <section className="blog-content">
          <section className="ev-Banner_sec">
            <div className="d-flex flex-column justify-content-center">
              <Row>
                <Col lg={12}>
                  <div className="coverup">
                    <h1>{item.Title}</h1>
                  </div>
                </Col>
              </Row>
            </div>
          </section>
          <Container>
            <Row>
              <Col lg={3}>
                <RelatedPost />
              </Col>
              <Col lg={9}>
                <div className="blog-content-info">
                  <img
                    src={"http://192.168.0.244:3004/" + item.Image}
                    alt="image not available"
                    onError={(e) => {
                      e.target.src = Image_not_available;
                    }}
                  />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item.LongDescription),
                    }}
                  ></div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      ))}
    </>
  );
}

export default BlogContent;
