import React from "react";
import { Col, Row } from "react-bootstrap";
import "./About.css";
import { NavLink, Link } from "react-router-dom";

function About() {
  return (
    <>
      <section className="pg-about">
        {/* Banner */}
        <section className="ev-Banner_sec">
          <div className="d-flex flex-column justify-content-center">
            <div className="grid-50 coverup">
              <h1>Edupreneur Village</h1>
              <p>The largest EdTech focused ecosystem in the country</p>
            </div>
          </div>
          <div className="ocean">
            <svg
              className="waves"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shape-rendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                />
              </defs>
              <g className="parallax">
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="0"
                  fill="rgba(255,255,255,1"
                />
                {/* <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                    <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                    <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                    <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />  */}
              </g>
            </svg>
          </div>
        </section>

        {/* About Content */}
        <section className="ev-about-sec">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col lg={6}>
                  <div className="grid-50 about-left_sec">
                    <div className="inner_wrapper">
                      <p>
                        We are an <span>EdTech focused ecosystem</span> based in
                        Delhi, India, focusing on equity investment, business
                        acceleration, growth and market access
                        <br />
                        <br />
                        Edupreneur Village is managed by Sri Aurobindo Society,
                        Puducherry, having a 60+ years legacy and 300+ world
                        centers running multiple Education Transformation
                        projects. Zero Investment Innovation in Education
                        Initiatives (ZIIEI) is the flagship non-profit Education
                        Reform programme running across 31+ Indian States & UTs
                        in 3 Lakh schools, covering 2.2 million teachers and 30
                        million beneficiaries kids.
                        <br />
                        <br />
                        Build scalable ventures in 3-step. Get access to equity
                        funding, customers and mentorship for better investment
                        readiness and execution.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="grid-50 about-right_sec">
                    <div className="about_img">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/W_Lc_HHJ5kw"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="flexi"></div>
            </div>
          </div>
        </section>
        {/* Philosophy */}
        <section className="ev-philosophy_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h3>Our Philosophy</h3>
                <p>
                  We are the founder's first ecosystem with a vast experience
                  with EdTech startups and education entrepreneurs. Our entire
                  focus lays on providing the best education sector focused
                  insights, experience, on ground relatability, market access
                  opportunities, and a personalized support structure to the
                  budding EdTech companies.
                </p>
                <div className="bottom_features">
                  <ul className="p-0">
                    <li>
                      <NavLink>
                        <img src="images/About/WeInspire.svg" />
                        <h5>We Inspire:</h5>
                        <p>
                          Create lasting innovative solutions for education
                          problems
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeNurture.svg" />
                        <h5>We Nurture:</h5>
                        <p>
                          Enabling environment for creating a minimum viable
                          product (MVP)
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeStabilize.svg" />
                        <h5>We Stabilize:</h5>
                        <p>
                          Help Entrepreneurs find the right
                          Product-Market-Channel fit
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeScale.svg" />
                        <h5>We Scale:</h5>
                        <p>
                          Enable ventures identify Product-Market-Channel to
                          grow users
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeMonetize.svg" />
                        <h5>We Monetize:</h5>
                        <p>
                          Help the venture stand on its own feet by establishing
                          profitability
                        </p>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Work with us */}
        {/* <section className="ev-work-withUs_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h2 className="main_head">Work With Us</h2>
                <p>We are looking for you, change maker!</p>
                <p className="para">
                  It’s difference that makes us great. Join a team where
                  diversity of ideas are welcomed, and we are all looking to get
                  better, one moment at a time.
                </p>
                <Link to="/career">
                  <button className="green_btn">
                    Careers
                    <img src="images/Home/white_arrow.svg" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section> */}
      </section>
    </>
  );
}

export default About;
