import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./ContactUs.css";
import React, { useEffect, useState } from "react";
import { getconfigurationList } from "services/configuration.service";
import { sendMailForContactUs } from "services/contactUs.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleKeyPressForNumbers } from "components/RegexValidation";
import { handleKeyPressforSpecialCharacters } from "components/RegexValidation";

function ContactUs() {
  const [configure, setConfigure] = useState([]);
  const [contactUsData, setContactUsData] = useState({});
  useEffect(() => {
    // console.log("Hiiiii");
    getconfigurationList()
      .then((response) => response.json())
      .then((data) => {
        setConfigure(data.Result);
      });
  }, []);

  const handleChange = (e) => {
    setContactUsData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMailForContactUs(contactUsData).then((response) => {
      if (response.data.status === "Success" && !response.data.error) {
        const notify = () => {
          toast.success(response.data.message);
        };
        notify();
        setContactUsData({});
      } else {
        const notify = () => {
          toast.error(response.data.message);
        };
        notify();
      }
    });
  };

  return (
    <>
      <section className="pg-contactus">
        {/* Banner */}
        <section className="ev-Banner_sec">
          <div className="d-flex justify-content-center flex-column">
            <div className="grid-50 coverup">
              <h1>Contact Us</h1>
            </div>
          </div>
        </section>
        {/* Contact Info */}
        <section className="contact_page" id="contact-frm">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col lg={5}>
                  <div
                    className="left_aside-contact"
                    style={{ overflow: "hidden;" }}
                  >
                    {configure.length > 0
                      ? configure.map((configures, index) => (
                          <>
                            {/* <ul>
                              <li>
                                <NavLink>
                                  <img src="../images/Footer/location.svg" />
                                  {configures.Address}
                                </NavLink>
                              </li>
                              <li>
                                <NavLink>
                                  <img src="../images/Footer/email.svg" />
                                  {configures.EmailID}
                                </NavLink>
                              </li>
                              <li>
                                <NavLink>
                                  <img src="../images/Footer/call.svg" />
                                  {configures.Phone}
                                </NavLink>
                              </li>
                            </ul> */}
                          </>
                        ))
                      : ""}
                    <div className="map_view">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3505.089492481246!2d77.19441541464163!3d28.537029682455593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSri+Aurobindo+Society+Campus+Shaheed+Jeet+Singh+Marg+Adchini+New+Delhi-+110017+!5e0!3m2!1sen!2sin!4v1558599247784!5m2!1sen!2sin"
                        height="300"
                        style={{ border: "0" }}
                      ></iframe>
                    </div>
                  </div>
                </Col>
                <Col lg={7}>
                  <div className="right_aside-contact_form">
                    <h3>
                      Get in <span>touch?</span>
                    </h3>
                    <form method="POST" onSubmit={handleSubmit}>
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        required
                        onKeyPress={handleKeyPressforSpecialCharacters}
                        onChange={handleChange}
                      />
                      <select name="offering" onChange={handleChange}>
                        <option value="investmentMarathon">
                          Investment Marathon
                        </option>
                        <option value="acceleration">Acceleration</option>
                        <option value="partnership">Partnership</option>
                      </select>
                      <input
                        type="text"
                        placeholder="Mobile"
                        name="mobile"
                        minLength={10}
                        maxLength={10}
                        required
                        onKeyPress={handleKeyPressForNumbers}
                        onChange={handleChange}
                      />
                      <input
                        type="email"
                        placeholder="E-mail"
                        name="email"
                        required
                        onChange={handleChange}
                      />
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Company Name"
                        required
                        name="company"
                        onChange={handleChange}
                      />
                      <textarea
                        type="text"
                        placeholder="Your message"
                        name="message"
                      ></textarea>
                      <button className="green_btn" type="submit">
                        Submit
                      </button>
                    </form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </section>
      <ToastContainer />
    </>
  );
}

export default ContactUs;
