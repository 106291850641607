import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  createBlog,
  updateBlog,
  getBlogId,
  deleteBlog,
  getActiveBlogCategory,
} from "services/blog.service";
import { handleKeyPressforSpecialCharacters } from "components/RegexValidation";
import { data } from "autoprefixer";
import { IMAGEURL } from "global";

export const BlogAdd = () => {
  const param = useParams();
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [partners, setPartners] = useState("");
  const [base64, setBase64] = useState("");
  const [size, setSize] = useState("");
  const [category, setCategory] = useState("");
  const [blogCategories, setBlogCategories] = useState([]);
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [blogID, setBlogID] = useState("");
  const [createby, setCreateby] = useState(user.AdminID);
  

  const [TitleError, setTitleError] = useState(false);
  const [error, setError] = useState(false);

  const uploadImage = (e) => {
    let file = e.target.files[0];
    let ext = file !== undefined ? file.name?.split(".")[1] : "";

    if (
      ext.toLowerCase() === "jpeg" ||
      ext.toLowerCase() === "jpg" ||
      ext.toLowerCase() === "png"
    ){
      if (file) {
        const reader = new FileReader();
        setSize(file.size);
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      }
    }
    else if(ext === ""){
      return false;
    }
    else{
      setBase64("");
      setImagePreview("");
      toast.error("Image must be jpg, jpeg and png extension");
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64(btoa(binaryString));
    setImagePreview("data:image/png;base64," + btoa(binaryString));
  };

  useEffect(() => {
    if (typeof param.id !== "undefined") {
      getBlogId(param.id).then((response) => {
        let blogDetail = response.data.Result[0];
        setBlogID(blogDetail.BlogID);
        setTitle(blogDetail.Title);
        setShortDescription(blogDetail.ShortDescription);
        setLongDescription(blogDetail.LongDescription);
        setCategory(blogDetail.BlogCategoryID);
        setImagePreview(blogDetail.Image);
      });
    }

    getActiveBlogCategory().then((response) => {
      setBlogCategories(response.data.Result);
    });
  }, []);

  const addBlog = async (e) => {
    e.preventDefault();

    if (title.trim() === '' || shortDescription.trim() === '' || longDescription.trim()=== '') {
      // Handle the case when the input field is blank
      toast.warn("Input field cannot be blank");
      return;
    }

    const blog = {
      id: e.target.id.value,
      title: title.replace("'", "''"),
      shortDescription: shortDescription.replace("'", "''"),
      longDescription: longDescription.replace("'", "''"),
      image: param.id ? (base64 !== "" ? base64 : "") : base64,
      blogCategoryID: category,
      createby: createby,
    };

    let response;

    if (e.target.id.value === "") {
      response = await createBlog(blog);
    } else {
      response = await updateBlog(blog);
    }

    if (response.status === 200 && !response.data.error) {
      // const notify = () => toast.success(response.data.Message);
      // notify();
      toast.success(response.data.Message);
      setTimeout(() => {
        navigate("/admin/blog");
      }, 2000);
    } else {
      // const notify = () => toast("Please enter the require valid field");
      // notify();
      toast.error(response.data.message);
    }
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              {/* <h3 className="mx-4 my-2 text-[24px] font-bold">Add New Blog</h3> */}

              <h3 className="mx-4 my-2 text-[24px] font-bold">
                {param.id ? "Edit" : "Add New"} Blog
              </h3>
            </div>

            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={addBlog}>
                    <input type="hidden" name="id" value={blogID} />
                    <input type="hidden" name="createby" value={createby} />
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Select Category
                      </label>
                      <select
                        name="category"
                        value={category}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value="">-- Select --</option>
                        {blogCategories.length > 0 &&
                          blogCategories.map((row, index) => {
                            return (
                              <option key={index} value={row.BlogCategoryID}>
                                {row.Title}
                              </option>
                            );
                          })}
                      </select>
                    </div>




                    

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={title}
                        // maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Title"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            setTitleError(false);
                            e.preventDefault();
                          } else {
                            setTitleError(false);
                            setTitle(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Short Description
                      </label>
                      <input
                        type="text"
                        name="shortDescription"
                        value={shortDescription}
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}
                        placeholder="Description"
                        // onChange={(e) => setShortDescription(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setShortDescription(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Long Description
                      </label>
                      <ReactQuill
                        theme="snow"
                        value={longDescription}
                        onChange={setLongDescription}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Upload Image
                      </label>
                      <input
                        type="file"
                        name="image"
                        required={param.id ? false : true}
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}


                        accept=".jpeg,.jpg,.png"
                        onChange={uploadImage}
                      />
                     {base64 === "" ? <img src={`${IMAGEURL}${imagePreview}`} width="100" alt=""/> :
                        <img src={`${imagePreview}`} width="100" alt="" />}
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default BlogAdd;
