import Funding from "components/front/Funding/Funding";
import About from "../components/front/About/About";
import Acceleration from "../components/front/Acceleration/Acceleration";
import Career from "../components/front/Careers/Career";
import ContactUs from "../components/front/Contact-Us/ContactUs";
import Dashboard from "../components/front/Dashboard/Dashboard";
import Home from "../components/front/Home/Home";
import Login from "../components/front/Login/SignUp/Login";
import Marathon from "../components/front/Marathon/Marathon";
import Media from "../components/front/Media/Media";
import Partnership from "../components/front/Partnership/Partnership";
import Portfolio from "../components/front/Portfolio/Portfolio";
import BlogContent from "components/front/Funding/BlogContent/BlogContent";
import PrivacyPolicy from "components/front/Privacy-Policy/PrivacyPolicy";
import DashboardForm from "components/front/Dashboard/Dashboard-Form/DashboardForm";
import ForgotPass from "components/front/Login/SignUp/ForgotPass/ForgotPass";

export const publicRoutes = [
  {
    path: "",
    component: <Home />,
  },
  { path: "about", component: <About /> },
  {
    path: "media",
    component: <Media />,
  },
  {
    path: "media",
    component: <Media />,
  },
  {
    path: "media",
    component: <Media />,
  },
  {
    path: "marathon",
    component: <Marathon />,
  },
  {
    path: "acceleration",
    component: <Acceleration />,
  },
  {
    path: "partnership",
    component: <Partnership />,
  },
  {
    path: "portfolios",
    component: <Portfolio />,
  },
  {
    path: "career",
    component: <Career />,
  },
  {
    path: "funding",
    component: <Funding />,
  },
  {
    path: "contactUs",
    component: <ContactUs />,
  },
  {
    path: "login",
    component: <Login />,
  },
  {
    path: "dashboard",
    component: <Dashboard />,
  },
  {
    path: "blogContent/:id",
    component: <BlogContent />,
  },
  {
    path: "privacyPolicy",
    component: <PrivacyPolicy />,
  },
  {
    path: "dashboardForm",
    component: <DashboardForm />,
  },
  {
    path: "forgotPass",
    component: <ForgotPass />,
  },
];
