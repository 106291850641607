import React, { useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import "./Acceleration.css";
import Slider from "react-slick";
import { NavLink, Link } from "react-router-dom";
import { ActiveTestimonial } from "services/testimonials.service";
import { dynamicAcceleration } from "services/configuration.service";
import { IMAGEURL } from "global";

function Acceleration() {
  const [testimonialData, setTestimonialData] = useState("");
  const [accelerationData, setAccelerationData] = useState([]);
  let setting2 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(()=>{
    ActiveTestimonial().then((response)=>{
      setTestimonialData(response.data.Result);
    })
    dynamicAcceleration().then((response)=>{
      setAccelerationData(response.data.Result[0]);
    })
  }, [])
  return (
    <>
      <section className="pg-acceleration">
        {/* Acceleration Banner */}
        <section className="ev-Banner_sec">
          <div className="d-flex justify-content-center flex-column">
            <div className="grid-100 coverup">
              <h1>EdTech Acceleration Programme</h1>
              <p>Get the right assistance to scale your Edtech Startup</p>
              {/* <Link to="/contactUs">
                <button className="main-cto-first">Connect with Us</button>
              </Link> */}
              <Link to="https://calendly.com/naresh-kumar-3/ev-acceleration-meet">
                <button className="main-cto-first">Connect with Us</button>
              </Link>
            </div>
          </div>
        </section>
        
        {/* Acceleration About */}
        <section className="ev-about-sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h2 className="main_head">Accelerate Your Startup</h2>
                <div className="grid-100 stats_sec">
                  <ul>
                    <li>
                      <div className="counter-box">
                        <NavLink>
                          <span className="count">12</span>
                        </NavLink>
                        <p>Weeks duration</p>
                      </div>
                    </li>
                    <li>
                      <div className="counter-box">
                        <NavLink>
                          <span className="count">12</span>
                        </NavLink>
                        <p>Aspects of Business Plan covered</p>
                      </div>
                    </li>
                    <li>
                      <div className="counter-box">
                        <NavLink>
                          <span className="count">5</span>
                        </NavLink>
                        <p>Information Books to build a strong startup</p>
                      </div>
                    </li>
                    <li>
                      <div className="counter-box">
                        <NavLink>
                          <span className="">1:1</span>
                        </NavLink>
                        <p>Custom sessions with the Head, Edupreneur Village</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Acceleration About more*/}
        <section className="ev-about-sec green_shape">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <div className="grid-100 items">
                  <Row>
                    <Col lg={3}>
                      <div className="view_box">
                        <img src="images/Acceleration/Equity-Funding.svg" alt="" />
                        <h3>Equity Funding</h3>
                        <p>
                          We may offer an amount ranging from ____ to ____ as
                          equity investment to help you build your early-stage
                          EdTech startups at Seed/ Pre-Series A stage.
                        </p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view_box">
                        <img src="images/Acceleration/Customers-Base.svg" alt="" />
                        <h3>Customers Base</h3>
                        <p>
                          Get market access and a strong customer base
                          consisting of Teachers and Students through
                          partnership with Auro Scholar, a micro-scholarship
                          programme.
                        </p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view_box">
                        <img src="images/Acceleration/Mentorship.svg" alt="" />
                        <h3>Mentorship</h3>
                        <p>
                          Get all round mentoring for your Seed/Pre-Series A
                          stage startup to create a robust Business Plan and
                          become investment ready.
                        </p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view_box">
                        <img src="images/Acceleration/EdTech-Ecosystem.svg" alt="" />
                        <h3>EdTech Ecosystem</h3>
                        <p>
                          Join an unparalleled platform for EdTech entrepreneurs
                          with robust industry networks, linkages, and
                          relationships with the ecosystem players.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col lg={6}>
                    <div className="grid-50 about-left_sec">
                      <div className="inner_wrapper">
                        <h3>
                          Our <span>Methodology</span>
                        </h3>
                        <p>
                          The program is divided into 6 fortnights. Each
                          fortnight covers specific areas of the business plan
                          in the following steps:
                        </p>
                        <ul>
                          <li>
                            Introduction webinar about the specific areas of
                            business plan where Industry experts to speak about
                            key nuggets and answer specific questions
                          </li>
                          <li>
                            Information Book (toolkit) to help you build a
                            Business Plan
                          </li>
                          <li>
                            Informative content related to each area of Business
                            Plan
                          </li>
                          <li>
                            One to one sessions with senior management of
                            Edupreneur Village to help you arrive at a strategy
                            for each aspect of Business Plan
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="grid-50 about-right_sec">
                      <div className="about_img">
                        <iframe
                          src="https://www.youtube.com/embed/dQx2TvhFwMc"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>
        {/* Week by Week Plan */}
        <section className="ev-week_plac-sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h2 className="main_head">Week by Week Plan</h2>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Week 1 & 2 - Arriving at unmet need and the right Business
                      Solution
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <h4>Problem Statement</h4>
                        <li>
                          Industry context - Information about the industry or
                          situation within which the Problem exists
                        </li>
                        <li>
                          Problem statement - Core user need which the venture
                          wishes to satisfy
                        </li>
                        <li>User group - Target group being targeted</li>
                        <li>
                          Problem depth- How deep is the problem for the defined
                          user groups
                        </li>
                        <li>
                          Problem endurance - Why do you think the problem will
                          exist for a long term
                        </li>
                      </ul>
                      <ul>
                        <h4>Solution</h4>
                        <li>
                          Idea Generation/ Solution Idea - Core idea to solve
                          the problem
                        </li>
                        <li>
                          Solution Thesis - Underlying reasons due to which the
                          solution idea above will successfully address the
                          problem
                        </li>
                        <li>
                          Product Description - Key modules to address the
                          problem/ MVP
                        </li>
                        <li>
                          User Journey - Flow of how user will go through
                          different modules
                        </li>
                        <li>
                          Value Hypothesis - Likely value perception in the eyes
                          of each user group due to which user group will use
                          the product
                        </li>
                        <li>
                          Why Now? - Reasons why it will succeed in present
                          social, economic, technology, regulatory or geographic
                          environment.
                        </li>
                        <li>
                          Validation - Proof that the idea has worked elsewhere
                          in India or outside
                        </li>
                        <li>
                          Vision - What do you really wish this product to be in
                          the long run?
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Week 3 & 4 - Market & Competitive Landscape
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <h4>Addressable Market Size</h4>
                        <li>
                          Target Group - Typical profile of the TG of users who
                          are likely to buy the offering
                        </li>
                        <li>
                          Annual Revenue per User – sales from a user in 1 year
                        </li>
                        <li>
                          Addressable Market Size calculation - Maximum
                          potential TG users X Potential ARPU
                        </li>
                        <li>Assumptions & Market growth drivers</li>
                      </ul>
                      <ul>
                        <h4>Competitive Landscape</h4>
                        <li>
                          Competitors – Serving the customer need with a similar
                          product - Direct vs Indirect
                        </li>
                        <li>
                          Differentiation viz-a-viz direct competitors, offering
                          analysis, traction & funding
                        </li>
                        <li>
                          USP/ Disruption - Whether the Solution will disrupt
                          existing businesses in favor of itself or it will
                          create its own niche
                        </li>
                        <li>
                          MOAT - Key accomplishments which will put your venture
                          in a favorable or superior business position compared
                          to competitors in the long run
                        </li>
                      </ul>
                      <ul>
                        <h4>Industry Outlook</h4>
                        <li> Industry forces – Michael Porter’s 5 forces</li>
                        <li>
                          Winner takes it all market or multiple players
                          co-exist
                        </li>
                        <li>
                          Stakeholder behavior - What are the general habits or
                          attitudes of your TG users, as per which they consume
                          your kind of product or service?
                        </li>
                        <li>
                          Change quotient - How strongly your TG users need to
                          change their above behavior to adopt your product?
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Week 5 & 6 - Business Model
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          Revenue Model/ Pricing Structure – identifying the key
                          sources of revenue
                        </li>
                        <li>
                          Production Method - How will different product
                          modules/offerings be created?
                        </li>
                        <li>
                          Sales/ Distribution Channel - Channels to be used and
                          method to sell through each channel
                        </li>
                        <li>
                          Servicing Method - Channels to service the TG users
                          and suppliers
                        </li>
                        <li>
                          Renewal Method - How are customer contracts renewed?
                        </li>
                        <li>
                          Unit Economics - Estimated numbers that show there is
                          money to be made at unit level, ensuring scalability
                        </li>
                        <li>
                          Growth Engine - How does the venture plan to grow?
                          Examples given to explain better.
                        </li>
                        <li>
                          Scalability - Whether revenues can grow faster than
                          the cost over time
                        </li>
                        <li>
                          Challenges in Business Model - Solvable difficulties
                          in making the business model work
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Week 7 & 8 - Business Plan (Financial, Operational&
                      Product)
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li> Revenue Plan – step-wise arrival at revenue</li>
                        <li>
                          Operational Metrics & Customer Segmentation Analysis
                        </li>
                        <li>Sales Cohort/ Funnel Analysis</li>
                        <li>Creating the financial model</li>
                        <li>Product Development Plan</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Week 9 & 10 - Investment Pitch Deck
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <h4>Investment Round</h4>
                        <li>
                          Capital stage & investment required – how to arrive at
                          the amount
                        </li>
                        <li>Valuation and its rationale</li>
                        <li>Fund utilization</li>
                        <li>Current round goals</li>
                        <li>Exit Strategy for investors</li>
                      </ul>
                      <h4>
                        Pitch Deck - The art of storytelling - what all should
                        be included and in how-much depth
                      </h4>
                      <ul>
                        <li>
                          What all should be included and in how much depth
                        </li>
                        <li>Demo Pitches</li>
                        <li>Do's & Don'ts in Storytelling</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Week 11 & 12 - Investment Committee Pitches
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          Make actual pitch to EV Investment Committee and
                          co-investors
                        </li>
                        <li>Get feedback</li>
                        <li>Forge relations to convert into investments</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </section>
        {/* What do you pay */}
        <section className="pay_info-sec">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col lg={12}>
                  <div className="grid-100">
                    <h2>What do you pay?</h2>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="part one">
                    <NavLink>
                      <img src="images/Acceleration/otp.svg" alt="" />
                    </NavLink>
                    <ul>
                      <li>
                        <h3>₹ {accelerationData?.OneTimePayment}</h3>
                      </li>
                      <li>
                        <p>as one time payment.</p>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="part two">
                    <NavLink>
                      <img src="images/Acceleration/sweat.svg" alt="" />
                    </NavLink>
                    <ul>
                      <li>
                        <h3>{accelerationData?.Equity}%</h3>
                      </li>
                      <li>
                        <p>sweat equity</p>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* Fueling Startup */}
        <section className="ev-offering_sec">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col lg={12}>
                  <h2 className="main_head">Fueling Startup With</h2>
                </Col>
                <Col lg={3}>
                  <div className="grid-25 first">
                    <div className="offering_box">
                      <NavLink>
                        <span>
                          <img src="images/Home/icons_Capital.svg" alt="" />
                        </span>
                        <h3>Advisory Services</h3>
                        <p className="para" style={{ display: "none" }}>
                          Get the right guidance to create a Business Plan and
                          make your Seed/Pre-Series A stage startup Investment
                          ready.
                        </p>
                      </NavLink>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="grid-25 second">
                    <div className="offering_box">
                      <NavLink>
                        <span>
                          <img src="images/Home/icons_customers.svg" alt="" />
                        </span>
                        <h3>Network</h3>
                        <p className="para" style={{ display: "none" }}>
                          Market access and a customer network through
                          partnership with Auro Scholar, a micro-scholarship
                          programme.
                        </p>
                      </NavLink>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="grid-25 third">
                    <div className="offering_box">
                      <NavLink>
                        <span>
                          <img src="images/Home/icons_mentor.svg" alt="" />
                        </span>
                        <h3>Ideas & Connection for Growth</h3>
                        <p className="para" style={{ display: "none" }}>
                          Get ideas and speak to industry experts to get answers
                          to all your questions regarding the EdTech industry.
                        </p>
                      </NavLink>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="grid-25 fourth">
                    <div className="offering_box">
                      <NavLink>
                        <span>
                          <img src="images/Home/icons_ecosystem.svg" alt="" />
                        </span>
                        <h3>Investment</h3>
                        <p className="para" style={{ display: "none" }}>
                          Scale your EdTech startup with an investment of upto
                          500k
                        </p>
                      </NavLink>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* Testimonials */}
        <section className="ev-testimonials_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h2 className="main_head">What People Say's</h2>
                <div className="inner_wrapper">
                  {/* Swiper carousel */}
                  <Slider {...setting2}>
                    {testimonialData?.length > 0 ? 
                    testimonialData.map((item)=>(
                      <div className="item2">
                      <img src={`${IMAGEURL}${item.ProfilePic}`} className="testi-img" alt="" />
                      <h5>
                        {item.Name}
                        <span>{item.Position}</span>
                      </h5>
                      <p>
                        {item.Content}
                      </p>
                    </div>
                    )):""
                    }
                  </Slider>

                  {/* <div className="owl-carousel owl-theme owl-2" id="testi">
                  <div className="item">
                    <img src="img/home/avatar.jpg" className="testi-img" />
                    <h5>
                      Dr. Ramesh Pokhriyal Nishank
                      <span>Minister of HRD , Govt. of India</span>
                    </h5>
                    <p>
                      Education transformation needs entrepreneurial innovation.
                      Pleased to know that Sri Aurobindo Society’s Edupreneur
                      Village has helped refine 1100+ Ed-tech innovations across
                      India to bring out the 50 most promising startups. Unique
                      education effort aligning with #StartupIndia
                    </p>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default Acceleration;
