import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetpassword } from "services/user.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

export default function Resetpassword() {
  const notify = () => {
    toast("Password Reset Successfully!");
  };

  const navigate = useNavigate();
  const resetInitialValues = {
    code: "",
    password: "",
    confirmPassword: "",
    adminID: localStorage.getItem("adminid"),
  };

  const [reset, setReset] = useState(resetInitialValues);

  const onValueChange = (e) => {
    setReset({ ...reset, [e.target.name]: e.target.value });
  };

  const ResetUser = async (e) => {
    e.preventDefault();

    try {
      let response = await resetpassword(reset);

      console.log(reset);
      console.log(response);
      // if (response.data.errorCode == 400) return false;
      if (response.data.errorCode === 200) {
        notify(); //this show toaster message
        setTimeout(() => {
          navigate("/admin/login");
        }, 3000);
        return response;
      } else {
        throw new errors("Bad user credential");
      }
    } catch (error) {
      console.log(error, "errrrrrr");
      toast.error("Bad user credential");
    }
  };

  const formSchema = Yup.object().shape({
    code: Yup.string().required("Invalid code"),
    password: Yup.string().required("Password is mendatory"),
    confirmPassword: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  // function onSubmit(ResetUser) {
  //   console.log(JSON.stringify(ResetUser, null, 4));
  //   return false;
  // }

  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blue-950 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          >
            <h1 className="text-center text-white text-3xl mt-20 ">
              Edupreneur Village
            </h1>
          </div>

          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-700 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-white text-center mb-6  font-bold">
                      <h1 className="text-2xl">Reset your password</h1>
                      <p className="text-xs pt-6 text-white">
                        A code is sended to your e-mail , Please verify your
                        e-mail{" "}
                      </p>
                    </div>
                    <form onSubmit={ResetUser}>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-white text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Code
                        </label>
                        <input
                          type="text"
                          name="code"
                          required
                          {...register("code")}
                          className={` border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                            errors.password ? "is-invalid" : ""
                          }`}
                          placeholder="Code"
                          onChange={(e) => onValueChange(e)}
                        />
                        <div className="invalid-feedback">
                          {errors.code?.message}
                        </div>
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-white text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          New-Password
                        </label>
                        <input
                          type="password"
                          required
                          name="password"
                          {...register("password")}
                          className={` border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                            errors.password ? "is-invalid" : ""
                          }`}
                          placeholder="New-Password"
                          onChange={(e) => onValueChange(e)}
                        />
                        <div className="invalid-feedback">
                          {errors.password?.message}
                        </div>
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-white text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Confirm-Password
                        </label>
                        <input
                          type="password"
                          required
                          name="confirmPassword"
                          {...register("confirmPassword")}
                          className={` border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                            errors.confirmPassword ? "is-invalid" : ""
                          }`}
                          placeholder="Confirm-Password"
                          onChange={(e) => onValueChange(e)}
                        />
                        <div className="invalid-feedback">
                          {errors.confirmPassword?.message}
                        </div>
                      </div>

                      <div className="text-center mt-6">
                        <button
                          type="submit"
                          className="bg-green-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          // onClick={notify}
                        >
                          Reset password
                        </button>
                      </div>

                      <div className="flex flex-wrap mt-6 relative">
                        <div className="w-1/2">
                          <NavLink to={"/admin"}>
                            <h1 className="text-center text-white font-bold">
                              Return to Login?
                            </h1>
                          </NavLink>

                          {/* <NavLink
                            to={"/acceleration"}
                            className="pe-auto"
                            state={{ id: 1 }}
                            onClick={() => handleNavClose("acceleration")}
                          >
                            Acceleration
                          </NavLink> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
}
