import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import BlogContent from "../BlogContent/BlogContent";
import "./Listing.css";
import { getBlogList, getActiveBlogList } from "services/blog.service";
import Image_not_available from "assets/img/Image_not_available.png";
function Listing() {
  let navigate = useNavigate();

  const [blogList, setblogList] = useState([]);
  useEffect(() => {
    BlogList();
  }, []);

  const BlogList = async () => {
    let response = await getActiveBlogList();
    setblogList(response.data.Result);
  };
  const handleClick = (str) => () => {
    navigate("/blogContent/" + str);
  };

  return (
    <>
      <section className="blog-list">
        {blogList.length > 0
          ? blogList.map((item) => (
              <>
                {console.log(item.BlogID)}
                <div className="blog-item" key={item.BlogID}>
                  <img
                    src={"http://192.168.0.244:3004/" + item.Image}
                    alt="image not available"
                    onError={(e) => {
                      e.target.src = Image_not_available;
                    }}
                  />
                  <div className="blog-content">
                    <p className="sub-text"> {item.ShortDescription}</p>
                    <p className="blog-head">{item.Title}</p>
                    <p className="blog-info">{item.ShortDescription}</p>
                    <button
                      className="head-cto"
                      onClick={handleClick(item.BlogID)}
                    >
                      Read more
                      <img src="images/Home/white_arrow.svg" />
                    </button>
                  </div>
                </div>
              </>
            ))
          : ""}
      </section>
    </>
  );
}

export default Listing;
