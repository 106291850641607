import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./Login.css";
import { userLogin, userAdd } from "services/user.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CommonService } from "services/common.service";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";
import { handleKeyPressforSpecialCharacters } from "components/RegexValidation";
import { handleKeyPressForNumbers } from "components/RegexValidation";
import { userNameRegex } from "components/RegexValidation";

function Login() {
  const [error, setError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [isSignupVisible, setIsSignupVisible] = useState(false);
  const [isMobileSignup, setMobileSignup] = useState(false);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  const commonService = new CommonService();

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setUserData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value.trim(),
      }));
      userData.userName = e.target.value.trim();
    } else {
      setUserData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value.trim(),
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userData.contact) {
      userAdd(userData)
        .then((response) => response.json())

        .then((data) => {
          console.log("data", data);
          if (data.Status === "Success") {
            setUserData({});
            setIsSignupVisible(false);
            const notify = () => {
              toast.success(data.Result.Message);
            };
            notify();
          } else {
            const notify = () => {
              toast.error(data.Message);
            };
            notify();
          }
        });
    } else {
      userLogin(userData).then((response) => {
        if (response.data.status === "success") {
          setUserData({});
          const notify = () => {
            toast.success(response.data.message);
          };
          notify();
          localStorage.setItem("isUserLoggedIn", true);
          localStorage.setItem(
            "loggedUser",
            JSON.stringify(response.data.result)
          );
          commonService.Authenticated.next(true);
          setTimeout(() => {
            navigate("/dashboard");
          }, 2000);
        } else {
          const notify = () => {
            toast.error(response.data.message);
          };
          notify();
        }
      });
    }
  };

  ////////
  // const formSchema = Yup.object().shape({
  //   userName: Yup.string().required("Invalid userName"),
  //   password: Yup.string().required("Password is mendatory"),
  // });

  // const formOptions = { resolver: yupResolver(formSchema) };
  // const { register, formState } = useForm(formOptions);
  // const { errors } = formState;
  ////////

  return (
    <>
      <section className="pg-login">
        <div className={`cont ${isSignupVisible ? "s--signup" : ""}`}>
          {/* <div className="cont"> */}
          <div className="form sign-in">
            <h2 style={{ color: "green" }}>Welcome to Edupreneur Village</h2>
            <label>
              <span>User Name</span>
              <input
                // {...register("userName")}
                type="text"
                name="userName"
                maxLength={50}
                required
                onChange={handleChange}

                // {...`${errors.password ? "is-invalid" : ""}`}
              />
              {/* <div className="invalid-feedback">{errors.userName?.message}</div> */}
            </label>
            <label>
              <span>Password</span>
              <input
                // {...register("password")}
                type="password"
                name="password"
                required
                minLength={6}
                maxLength={15}
                onKeyPress={(e) => {
                  if (e.which === 32) {
                    e.preventDefault();
                  }
                }}
                onChange={handleChange}
              />
              {/* <div className="invalid-feedback">{errors.password?.message}</div> */}
            </label>
            <Link to="/forgotpass">
              <p className="forgot-pass">Forgot password?</p>
            </Link>

            <NavLink href="/" className="text-decoration-none">
              <Link to="/dashboard">
                <button type="button" className="submit" onClick={handleSubmit}>
                  Sign In
                </button>
              </Link>
            </NavLink>

            <button type="button" className="fb-btn">
              Connect with <span>facebook</span>
            </button>
          </div>

          <div className="sub-cont">
            <div className="img">
              <div className="img__text m--up">
                <h2>New here?</h2>
                <p>Sign up and discover great amount of new opportunities!</p>
              </div>
              <div className="img__text m--in">
                <h2>One of us?</h2>
                <p>
                  If you already has an account, just sign in. We've missed you!
                </p>
              </div>
              <div
                className="img__btn"
                onClick={() => setIsSignupVisible((prev) => !prev)}
              >
                <span className="m--up">Sign Up</span>
                <span className="m--in">Sign In</span>
              </div>
            </div>

            <div className="form sign-up">
              <h2 style={{ color: "green" }}>Register With Us</h2>
              <label>
                <span>
                  Name<span style={{ color: "red" }}>*</span>
                </span>
                <input
                  type="text"
                  name="name"
                  required
                  minLength={3}
                  maxLength={50}
                  onChange={handleChange}
                  onKeyPress={handleKeyPressforSpecialCharacters}
                />
              </label>
              <label>
                <span>
                  Mobile No.<span style={{ color: "red" }}>*</span>
                </span>
                <input
                  type="text"
                  minLength={10}
                  maxLength={10}
                  required
                  name="contact"
                  onChange={handleChange}
                  onKeyPress={handleKeyPressForNumbers}
                />
              </label>

              <label>
                <span>
                  Email address<span style={{ color: "red" }}>*</span>
                </span>
                <input
                  type="email"
                  name="email"
                  required
                  minLength={11}
                  maxLength={50}
                  onChange={handleChange}
                />
              </label>

              <label>
                <span>
                  Password<span style={{ color: "red" }}>*</span>
                </span>
                <input
                  type="password"
                  name="password"
                  required
                  onKeyPress={(e) => {
                    if (e.which === 32) {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleChange}
                />
              </label>

              <div>
                <button type="button" className="submit" onClick={handleSubmit}>
                  Sign Up
                </button>
                <button type="button" className="fb-btn">
                  Join with <span>facebook</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Form */}
        <div className={`login-mobile ${isMobileSignup ? "signupAdd" : ""}`}>
          <div className="form sign-in">
            <h2 style={{ color: "green" }}>Welcome to Edupreneur Village</h2>
            <label>
              <span>Email</span>
              <input type="email" />
            </label>
            <label>
              <span>Password</span>
              <input type="password" />
            </label>
            <Link className="forgot-pass" to="/forgotpass">
              Forgot password?
            </Link>
            <NavLink href="/" className="text-decoration-none">
              <Link to="/dashboard">
                <button type="button" className="submit">
                  Sign In
                </button>
              </Link>
            </NavLink>
            <button type="button" className="fb-btn">
              Connect with <span>facebook</span>
            </button>
            <div className="login-mobile-or">
              <div className="or-block">
                <p>OR</p>
                <hr />
              </div>
              <p>Register With Us</p>
              <button
                className="mobileview-btn"
                onClick={() => setMobileSignup((prev) => !prev)}
              >
                Sign Up
              </button>
            </div>
          </div>
          <div className="form sign-up">
            <h2 style={{ color: "green" }}>Register With Us</h2>
            <label>
              <span>
                Name<span style={{ color: "red" }}>*</span>
              </span>
              <input type="text" name="name" onChange={handleChange} />
            </label>
            <label>
              <span>
                Mobile No.<span style={{ color: "red" }}>*</span>
              </span>
              <input type="number" name="contact" onChange={handleChange} />
            </label>

            <label>
              <span>
                Email address<span style={{ color: "red" }}>*</span>
              </span>
              <input type="email" name="email" onChange={handleChange} />
            </label>
            <label>
              <span>
                Password<span style={{ color: "red" }}>*</span>
              </span>
              <input type="password" name="password" onChange={handleChange} />
            </label>

            <button type="button" className="submit">
              Sign Up
            </button>
            <button type="button" className="fb-btn">
              Join with <span>facebook</span>
            </button>
            <div className="login-mobile-or">
              <div className="or-block">
                <p>OR</p>
                <hr />
              </div>
              <p>Already User</p>
              <button
                className="mobileview-btn"
                onClick={() => setMobileSignup((prev) => !prev)}
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default Login;
