import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./PrivacyPolicy.css";
import { getContentPrivacyPolicy } from "services/content.service";

function PrivacyPolicy() {
  const [contentPolicy, setContent] = useState([]);

  useEffect(() => {
    getContentPrivacyPolicy()
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setContent(data.Result);
      });
  }, []);

  return (
    <>
      <section className="pg-privacy">
        {/* Banner */}
        <section className="ev-Banner_sec">
          <div className="d-flex flex-column justify-content-center">
            <div className="grid-50 coverup">
              <h1>Privacy Policy</h1>
            </div>
          </div>
        </section>
        <section className="privacy-content">
          <Container className="py-[50px]">
            {contentPolicy.length > 0
              ? contentPolicy.map((privacyContent, index) => (
                  <>
                    <h2 className="text-2xl mb-2 font-bold">
                      {privacyContent.Title}
                    </h2>
                    <p className="mb-2">{privacyContent.Description}</p>
                  </>
                ))
              : ""}
          </Container>
        </section>
      </section>
    </>
  );
}

export default PrivacyPolicy;
